







































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  components: {
    OEventData: () =>
      import("@/components/organisms/event/eventData/o-event-data.vue"),
    OEventModules: () =>
      import("@/components/organisms/event/eventData/o-event-modules.vue"),
    OEventSummary: () =>
      import("@/components/organisms/event/o-event-summary.vue"),
  },

  setup(_, { root }) {
    const model = reactive<{
      stepper: number;
      data: any;
      modules: any;
    }>({
      stepper: 1,
      data: {
        language: "polish",
        type: "conference",
        currency: "PLN",
        freeRegistration: false,
      },
      modules: {},
    });

    const dataSummary = computed(() => ({
      data: model.data,
      modules: model.modules,
    }));

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      errors: computed((): StepError[] => {
        const errors: StepError[] = [];

        if (!model.data.name)
          errors.push({ step: 1, error: "Podaj nazwę wydarzenia." });
        if (!model.data.prefix)
          errors.push({ step: 1, error: "Podaj prefix wydarzenia." });
        if (!model.data.type)
          errors.push({ step: 1, error: "Wybierz typ wydarzenia." });
        if (!model.data.language)
          errors.push({ step: 1, error: "Wybierz język wydarzenia." });
        if (!model.data.startDate)
          errors.push({ step: 1, error: "Podaj datę startu wydarzenia." });
        if (!model.data.endDate)
          errors.push({ step: 1, error: "Podaj datę końca wydarzenia." });
        if (!model.data.startTime)
          errors.push({
            step: 1,
            error: "Podaj godzinę rozpoczęcia wydarzenia.",
          });
        if (!model.data.endTime)
          errors.push({
            step: 1,
            error: "Podaj godzinę zakończenia wydarzenia.",
          });
        if (model.data.prefix && model.data.prefix.length !== 5)
          errors.push({ step: 1, error: "Podaj prefix wydarzenia." });

        return errors;
      }),
    });

    const isStepEditable = () => {
      return true;
    };

    const isStepComplete = (step: number) => {
      return (
        model.stepper > step &&
        !state.errors.find((error) => error.step == step)
      );
    };

    const isStepValid = (step: number) => {
      return !state.errors.find((error) => error.step == step);
    };

    const isFormValid = computed(() => !state.errors.length);

    const goToErrors = () => {
      let lowest = Number.POSITIVE_INFINITY;
      let highest = Number.NEGATIVE_INFINITY;

      let tmp: number;

      for (let i = state.errors.length - 1; i >= 0; i--) {
        tmp = state.errors[i].step;
        if (tmp < lowest) lowest = tmp;
        if (tmp > highest) highest = tmp;
      }

      model.stepper = lowest;
    };

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        prefix: model.data.prefix,
        type: model.data.type,
        siemensPrefix:
          model.data.type === "siemens" ? model.data.siemensPrefix : null,
        languageVersion: model.data.language,
        startDate: model.data.startDate,
        endDate: model.data.endDate,
        startTime: model.data.startTime || null,
        endTime: model.data.endTime || null,
        freeRegistration: model.data.freeRegistration,
        endRegistrationDate: model.data.endRegistrationDate,
        amount: model.data.amount || undefined,
        currency: model.data.currency,
        invoiceModuleEnabled: true,
        companyModuleEnabled: model.modules.companyModuleEnabled,
        abstractModuleEnabled: model.modules.abstractModuleEnabled,
        budgetModuleEnabled: model.modules.budgetModuleEnabled,
        posterSessionModuleEnabled: model.modules.posterSessionModuleEnabled,
        surveyModuleEnabled: model.modules.surveyModuleEnabled,
        printModuleEnabled: model.modules.printModuleEnabled,
        additionalServiceModuleEnabled:
          model.modules.additionalServiceModuleEnabled,
        accommodationModuleEnabled: model.modules.accommodationModuleEnabled,
      };

      state.loading = true;

      axiosInstance
        .post("event", data)
        .then(() => {
          state.success = true;
          model.stepper = 1;
          model.data = {
            language: "polska",
            type: "conference",
            currency: "PLN",
            freeRegistration: false,
          };
          model.modules = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return "Sprawdź poprawność danych";
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Wydarzenie już istnieje";
        case 410:
          return "Prefix konfernecji już istnieje";
        case 411:
          return "Nie znaleziono szablonu RODO";
        case 412:
          return "Nie znaleziono szablonu Regulaminu";
        case 413:
          return "Nie znaleziono szablonu polityki prywatności";
        case 414:
          return "Nie znaleziono szablonu wiadomości po rejestracji";
        case 415:
          return "Nie znaleziono szablonu podsumowania rejestracji";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      model,
      state,
      isStepEditable,
      isStepComplete,
      isStepValid,
      isFormValid,
      goToErrors,
      dataSummary,
      onSubmit,
      getErrorMessage,
    };
  },
});
